import { render, staticRenderFns } from "./Consultar.vue?vue&type=template&id=9988dcf6&lang=pug"
import script from "./Consultar.vue?vue&type=script&lang=js"
export * from "./Consultar.vue?vue&type=script&lang=js"
import style0 from "./Consultar.vue?vue&type=style&index=0&id=9988dcf6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports