<template lang="pug">
	.main-wrapper.relatorio-medico
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Relatório Médico</b>
		
		Panel(header='Gerar relatório')
			form(@submit.prevent='consultar')
				.p-grid.p-fluid.p-align-end
				
					.p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_guia_situacao.$error }")
						label.form-label Situacão da guia:
						Dropdown(v-model='$v.model.cd_guia_situacao.$model'
							:options='options.cd_guia_situacao'
							optionLabel='label'
							optionValue='value'
							placeholder='Selecione')
						.feedback--errors(v-if='submitted && $v.model.cd_guia_situacao.$error')
							.form-message--error(v-if="!$v.model.cd_guia_situacao.required") <b>Situacão</b> é obrigatório.

					.p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.dt_inicial.$error }")
						label.form-label Data inicial:
						Calendar(v-model='$v.model.dt_inicial.$model' dateFormat="dd/mm/yy" :locale="ptbr")
						.feedback--errors(v-if='submitted && $v.model.dt_inicial.$error')
							.form-message--error(v-if="!$v.model.dt_inicial.required") <b>Data inicial</b> é obrigatório.

					.p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.dt_final.$error }")
						label.form-label Data final:
						Calendar(v-model='$v.model.dt_final.$model' dateFormat="dd/mm/yy" :locale="ptbr")
						.feedback--errors(v-if='submitted && $v.model.dt_final.$error')
							.form-message--error(v-if="!$v.model.dt_final.required") <b>Data final</b> é obrigatório.

					.p-col-12.p-md-2.ta-center
						ProgressSpinner.waitingForm(v-if='waitingForm' strokeWidth='4')
						Button(v-else icon='jam jam-search' label='Gerar')
		
		Panel.mt-2(v-if='relatorio.grafico' header='Relatório')
			.p-grid.p-fluid
				.p-col-12.p-md-5
					Chart(type='line' :data='relatorio.grafico')
					div(style='margin-top:-10px')
						p(style='font-size:12px') Data de {{ model.cd_guia_situacao === 5 ? 'execução' : 'emissão' }} da guia <i class='jam jam-arrow-right' style='vertical-align:middle'></i>
				.p-col-12.p-md-7
					DataTable.table-relatorio(:value='relatorio.tabela')
						Column(headerStyle='width:15%' field='cd_guia_eletronica.id' header='Cód. guia' bodyStyle='text-align:center')
						Column(headerStyle='width:40%' header='Paciente')
							template(#body='props')
								span <b>{{ props.data.cd_guia_eletronica.cd_paciente.nm_pessoa_fisica }}</b><br/>
								span(v-if='props.data.cd_guia_eletronica.cd_paciente.nr_cpf_f') CPF: {{ props.data.cd_guia_eletronica.cd_paciente.nr_cpf_f }}
						Column(headerStyle='width:15%' header='Agendamento')
							template(#body='props')
								span <b>{{ props.data.cd_guia_eletronica.cd_horario_agendamento.dt_agenda_f }}</b><br/>
								span {{ props.data.cd_guia_eletronica.cd_horario_agendamento.hr_agenda_f }}
						Column(headerStyle='width:15%' field='cd_guia_eletronica.cd_guia_situacao_f' header='Situação' bodyStyle='text-align:center')
						Column(headerStyle='width:15%' field='nr_valor_repasse_f' header='Valor repasse' bodyStyle='text-align:right')
					
					table(style='width:100%;font-size:12px;border-collapse:collapse')
						tr.ta-right(style='background-color:#f4f4f4')
							td.px-1(style='width:85%;border:1px solid #c8c8c8;border-top:0') <b>Total:</b>
							td(style='width:15%;border:1px solid #c8c8c8;border-top:0;padding:0.429em 0.857em') {{ relatorio.total_repasse }}

					.ta-right.mt-2
						ProgressSpinner.waitingDownload(v-if='waitingDownload' strokeWidth='4')
						Button(v-else style='font-size:12px;max-width:160px' label='Baixar CSV' icon='jam jam-download' @click='baixarArquivo')

</template>

<style lang="scss">
	.relatorio-medico {
		.waitingForm, .waitingDownload { width: 29px; height: auto; }
		.table-relatorio td,
		.table-relatorio th { font-size: 12px; }
		@media all and (max-width: 576px) {
			.table-relatorio {
				overflow-x: scroll;
				table {
					min-width: 688px;
				}
			}
		}
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import Dropdown from 'primevue/dropdown'
	import Calendar from 'primevue/calendar'
	import Button from 'primevue/button'
	import ProgressSpinner from 'primevue/progressspinner'
	import Chart from 'primevue/chart'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'

	import { pCalendarLocale_ptbr } from './../../utils'
	import { required } from 'vuelidate/lib/validators'
	import { AtendimentoMedico } from '../../middleware'
	import moment from 'moment'
	import { saveAs } from 'file-saver'

	export default {
		components: { Panel, Dropdown, Button, Calendar, ProgressSpinner, Chart, DataTable, Column },
		data () {
			return {
				model: {
					cd_guia_situacao: 5,
					dt_inicial: moment().subtract(1, 'months')._d,
					dt_final: moment()._d
				},
				options: {
					cd_guia_situacao: [
						{ value: 4, label: 'Agendado' },
						{ value: 5, label: 'Executado' }
					]
				},
				relatorio: {
					grafico: null,
					tabela: null,
					total_repasse: null
				},
				ptbr: pCalendarLocale_ptbr,
				submitted: false,
				waitingForm: false,
				waitingDownload: false
			}
		},
		validations () {
			let v = {
				model: {
					cd_guia_situacao: { required },
					dt_inicial: { required },
					dt_final: { required }
				}
			}
			return v
		},
		methods: {
			consultar () {
				this.submitted = true

				this.$v.$touch()
				if (this.$v.$invalid) return 0

				let dataSend = Object.assign({}, this.model)
				this.relatorio = { grafico: null, tabela: null, total_repasse: null }
				
				dataSend.dt_inicial = moment(dataSend.dt_inicial).format('YYYY-MM-DD')
				dataSend.dt_final = moment(dataSend.dt_final).format('YYYY-MM-DD')

				this.waitingForm = true
				AtendimentoMedico.getRelatorio(dataSend).then(response => {
					if (response.status === 200)  {

						this.relatorio.tabela = response.data
						this.relatorio.total_repasse = 0

						this.relatorio.tabela.forEach(item => {
							if (item.cd_guia_eletronica.cd_paciente.nr_cpf)
								item.cd_guia_eletronica.cd_paciente.nr_cpf_f =
									item.cd_guia_eletronica.cd_paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
						
							item.cd_guia_eletronica.cd_horario_agendamento.dt_agenda_f =
								moment(item.cd_guia_eletronica.cd_horario_agendamento.dt_agenda).format('DD/MM/YYYY')

							item.cd_guia_eletronica.cd_horario_agendamento.hr_agenda_f =
								moment(item.cd_guia_eletronica.cd_horario_agendamento.hr_agenda, 'HH:mm:ss', true).format('HH:mm')
						
							let find = this.options.cd_guia_situacao.find(option => option.value === item.cd_guia_eletronica.cd_guia_situacao)
							if (find) item.cd_guia_eletronica.cd_guia_situacao_f = find.label

							item.nr_valor_repasse_f = this.formatPrice(item.nr_valor_repasse)
							this.relatorio.total_repasse += parseFloat(item.nr_valor_repasse)
						})

						this.relatorio.total_repasse = this.formatPrice(this.relatorio.total_repasse)

						AtendimentoMedico.getRelatorioGraficos(dataSend).then(response => {
							if (response.status === 200) {
								this.relatorio.grafico = response.data

								let data = response.data.map(data => {
									return {
										dt_execucao_f: moment(data.cd_guia_eletronica.dt_execucao).format('DD/MM/YYYY'),
										dt_execucao: moment(data.cd_guia_eletronica.dt_execucao)._d,
										dt_emissao_f: moment(data.cd_guia_eletronica.dt_emissao).format('DD/MM/YYYY'),
										dt_emissao: moment(data.cd_guia_eletronica.dt_emissao)._d,
										nr_valor_repasse: parseFloat(data.nr_valor_repasse)
									}
								})

								let key = this.model.cd_guia_situacao === 5 ? 'dt_execucao' : 'dt_emissao'

								data = data.sort((d1, d2) => { return d1[key] < d2[key] ? -1 : 1 })
								this.relatorio.grafico = {
									labels: data.map(data => data[`${ key }_f`]),
									datasets: [
										{
											label: 'Valor de repasse (R$)',
											backgroundColor: '#007ad9',
											data: data.map(data => data.nr_valor_repasse)
										}
									]
								}

							}
							this.waitingForm = false
						})
					}  else if (response.status == 400) {
						if (response.data.detail) {
							if (typeof response.data.detail == 'string')
								this.$toast.error(response.data.detail, { duration: 3000 })
							else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
						this.waitingForm = false
					}
				})
			},
			baixarArquivo () {
				let dataSend = Object.assign({}, this.model)

				dataSend.dt_inicial = moment(dataSend.dt_inicial).format('YYYY-MM-DD')
				dataSend.dt_final = moment(dataSend.dt_final).format('YYYY-MM-DD')
				dataSend.csv = true

				this.waitingDownload = true
				AtendimentoMedico.getRelatorio(dataSend).then(response => {
					this.waitingDownload = false
					if (response.status === 200) {
						let blob = new Blob([response.data], { type: 'text/plain; charset=utf-8' })
						//let fileURL = URL.createObjectURL(blob)
						//let a = window.open(fileURL)
						//a.print()
						saveAs(blob, `relatorio_medico_${ moment().format('YYYYMMDD_HHmmss') }.csv`)
					}
				})
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			}
		}
	}
</script>